@import '../../styles/variables.css';

.container {
  position: relative;
  height: 65vh;
  background-color: var(--header-ui-color);

  @media (--tablet-landscape-up) {
    height: 85vh;
  }
}

body .media {
  position: relative;
  background-color: var(--black);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.14) 0%,
      rgba(0, 0, 0, 0.12) 13.69%,
      rgba(131, 131, 131, 0) 48.02%,
      rgba(0, 0, 0, 0) 76.83%,
      rgba(0, 0, 0, 0.31) 100%
    );
    z-index: var(--z-default);
  }

  img {
    object-position: top center;
  }
}

body .mediaMobile {
  @media (--tablet-landscape-up) {
    display: none;
  }
}

body .mediaDesktop {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
  }
}

.link {
  display: block;
  height: 100%;
}

.info {
  position: absolute;
  left: 50%;
  bottom: 55px;
  transform: translateX(-50%);
  @mixin max-width var(--container-max-width);
  padding: 0 var(--container-padding);
  z-index: var(--z-default);

  @media (--tablet-landscape-up) {
    bottom: 73px;
  }
}

.mainTitleContainer {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(var(--header-height) + 24px);
  color: var(--header-slider-ui-color);
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--container-padding);
  z-index: var(--z-default);
}

.mainTitle {
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: right;

  margin-bottom: 0;

  @media (--tablet-landscape-up) {
    font-size: 16px;
    line-height: 20px;
  }
}

.title,
.description {
  margin-bottom: 0;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--header-slider-ui-color);

  @media (--tablet-landscape-up) {
    font-size: 24px;
    line-height: 31px;
  }
}

.description {
  @mixin font-thin;
}

.progressBar {
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  @mixin max-width var(--container-max-width);
  height: 2px;
  padding: 0 var(--container-padding);

  @media (--tablet-landscape-up) {
    bottom: 45px;
  }

  &::before {
    opacity: 0.2;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: var(--container-padding);
    right: var(--container-padding);
    height: 100%;
    background-color: var(--header-slider-ui-color);
  }
}

.progressBarIndicator {
  position: relative;
  width: 50%;
  height: 2px;
  background-color: var(--header-slider-ui-color);
  transition: width 0.4s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -4.5px;
    right: 0;
    width: 2px;
    height: 12px;
    background-color: var(--header-slider-ui-color);
  }
}
