@import '../../styles/variables.css';

.container {
  margin-bottom: 0;
  @mixin font-family-menu;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.swapColour {
  -webkit-text-stroke: 1px var(--black);
  color: var(--white);

  .subtitle {
    text-shadow: none;
    color: var(--black);
  }
}

.lightUi {
  color: var(--white);

  &.swapColour {
    color: transparent;
    -webkit-text-stroke: 1px var(--white);

    .subtitle {
      -webkit-text-stroke: unset;
      color: var(--white);
    }
  }

  .subtitle {
    -webkit-text-stroke: 1px var(--white);
  }
}

.borderTitle {
  -webkit-text-stroke: 1px var(--white);
  color: var(--black);
}

.subtitle {
  display: block;
  -webkit-text-stroke: 1px var(--black);
  color: transparent;
}

.subtitleLong {
  font-size: 22px;
  line-height: 25px;

  @media (--desktop-up) {
    font-size: 32px;
    line-height: 36px;
  }

  @media (--big-desktop-up) {
    font-size: 50px;
    line-height: 56px;
  }
}

.lightSubtitle .subtitle {
  color: var(--white);
}
