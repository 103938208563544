@import '../../styles/variables.css';

.container {
  background-color: var(--black);

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: var(--container-padding);
    bottom: 0;
    right: var(--container-padding);
    height: 1px;
    background-color: #383838;

    @media (--tablet-landscape-up) {
      content: none;
    }
  }
}

.wrapper {
  @mixin max-width var(--container-max-width);
  padding: var(--container-padding);

  @media (--tablet-landscape-up) {
    display: flex;
    padding: 50px var(--container-padding) 62px;
  }
}

.slider {
  @media (--tablet-landscape-up) {
    width: 40%;
  }
}

body .img {
  width: 100%;
}

.infoWrapper {
  padding-top: 34px;
  padding-bottom: 8px;

  @media (--tablet-landscape-up) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding: 10% 0;
  }
}

.info {
}

body .title {
  margin-bottom: 18px;

  @media (--tablet-landscape-up) {
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 34px;
  }
}

.description {
  max-width: 440px;
  margin-bottom: 28px;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--white);

  @media (--tablet-landscape-up) {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 31px;
  }
}

.link {
  display: table;
  width: auto;
  padding: 8px 16px;
  @mixin font-bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid var(--white);
  color: var(--white);
  background-color: var(--black);
  transition: color 0.2s, background-color 0.2s;

  @media (--tablet-landscape-up) {
    display: block;
    width: 292px;
    padding: 0;
    font-size: 15px;
    line-height: 36px;
  }

  &:hover {
    color: var(--black);
    background-color: var(--white);
  }
}
