@import '../../styles/variables.css';

.container {
  @mixin max-width var(--container-max-width);
}

.logo {
  display: block;
  width: 280px;
  height: 71px;
  margin-bottom: var(--space-xxl);

  @media (--tablet-portrait-up) {
    width: 400px;
    height: 102px;
  }
}

.locations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.location {
  @mixin btn-reset;
  margin: 0 var(--space-sm) var(--space-md) var(--space-sm);
  @mixin font-bold;
  text-transform: uppercase;
  color: var(--white);

  &:after {
    opacity: 0;
    content: '';
  }

  &:hover {
    @mixin link-underline var(--white), -2px;
  }
}

.noItems {
  text-align: center;
  color: var(--white);
}
