@import '../../styles/variables.css';

.container {
  padding-bottom: 60px;
  background-color: #1b1b1b;

  @media (--tablet-landscape-up) {
    padding-bottom: 100px;
  }
}

.wrapper {
  display: flex;
  overflow-x: auto;
  @mixin max-width var(--container-max-width);
  /* margin-right: calc(var(--container-padding) * -1 - 56px); */
  padding: 33px var(--container-padding);

  @media (--tablet-landscape-up) {
    padding: var(--container-padding);
  }

  @mixin scrollbar-height;

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--white);
  }
}

.project {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 60%;
  margin-right: 33px;
  cursor: pointer;

  @media (--tablet-landscape-up) {
    width: 26%;
    margin-right: 46px;
  }

  @media (--tablet-desktop-up) {
    width: 20%;
    margin-right: 56px;
  }
}

.imgWrap {
  position: relative;
  margin-bottom: 16px;

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(4 / 3 * 100%);
  }
}

body .img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  img {
    @mixin fill-image;
    object-position: top center;
  }
}

.title,
.subtitle {
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  color: var(--white);

  @media (--tablet-landscape-up) {
    margin-bottom: 10px;
    line-height: 21px;
  }
}

.title {
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  text-transform: uppercase;
}

.subtitle {
  @mixin font-regular;
}
