@import '../../../styles/variables.css';

.container {
    display: none;

    @media (--tablet-landscape-up) {
        display: block;
        padding: 34px var(--container-padding);
    }

    p {
        margin-bottom: 16px;
    }

    p:last-child {
        margin-bottom: 0;
    }

    a {
        font-weight: bold;
    }
}

.accordion {
}

.accordion__item + .accordion__item {
}

.accordion__button {
    font-weight: bold;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;

    &:before {
        display: inline-block;
        content: '';
        height: 10px;
        width: 10px;
        margin-right: 12px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
    }

    &[aria-expanded='true']::before,
    &[aria-selected='true']::before {
        transform: rotate(45deg);
    }
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
