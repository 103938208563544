@import '../../../styles/variables.css';

.container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 42px 0 42px var(--container-padding);

  @media (--tablet-landscape-up) {
    overflow: initial;
    display: block;
    padding: 53px var(--container-padding);
  }

  body .title {
    order: 1;
  }

  .links {
    order: 2;
  }

  .slider {
    order: 3;
  }

  /* &::before {
    content: '';
    display: block;
    position: absolute;
    left: var(--container-padding);
    bottom: 42px;
    width: 100%;
    height: 6px;
    background-color: rgba(0, 0, 0, 0.08);

    @media (--tablet-landscape-up) {
      content: none;
    }
  } */
}

.header {
  margin-bottom: 32px;

  @media (--tablet-landscape-up) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 39px;
  }
}

body .title {
  margin-bottom: 40px;

  @media (--tablet-landscape-up) {
    margin-bottom: 0;
  }
}

.links {
  display: flex;
  transform: translateY(-45px);

  @media (--tablet-landscape-up) {
    position: absolute;
    top: 72px;
    right: var(--container-padding);
    transform: none;
  }
}

.link {
  opacity: 0.6;
  @mixin btn-reset;
  position: relative;
  margin-right: 15px;
  padding-left: 35px;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  transition: opacity 0.2s;

  @media (--tablet-portrait-up) {
    margin-right: 25px;
    padding-left: 45px;
  }

  @media (--tablet-landscape-up) {
    margin-right: 0;
    margin-left: 42px;
  }

  &:hover {
    opacity: 1;
  }
}

.linkActive {
  opacity: 1;
}

.linkWomens,
.linkMens {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media (--tablet-portrait-up) {
      width: 33px;
      height: 33px;
    }
  }
}

.linkWomens::before {
  background-image: url('/img/svg/arrow-circle-black.svg');
}

.linkMens::before {
  background-image: url('/img/svg/arrow-square-black.svg');
}

.posts {
  overflow-y: scroll;
  white-space: nowrap;
  margin-left: calc(var(--container-padding) * -1);
  padding-left: var(--container-padding);
  padding-bottom: 20px;

  @media (--tablet-landscape-up) {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  @mixin scrollbar-height;
}

.model {
  display: inline-flex;
  flex-direction: column;
  width: 50%;

  @media (--tablet-landscape-up) {
    display: flex;
    width: 100%;
  }
}

.imgWrap {
  position: relative;
  margin-bottom: 30px;
  background-color: #f6f6f6;

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(4 / 3 * 100%);
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  img {
    @mixin fill-image;
    object-position: top center;
  }
}

.name {
  margin-left: 5px;
  margin-bottom: 0;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 97%;
  letter-spacing: 1px;
  white-space: pre-line;
  text-transform: uppercase;
}
