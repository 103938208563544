@import '../../styles/variables.css';

.container {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-image: url(/img/loader-2.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--white);
  background-size: 100px;
}
