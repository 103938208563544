@import '../../../styles/variables.css';

.container {
  padding: 34px 0 34px var(--container-padding);

  @media (--tablet-landscape-up) {
    padding: 34px var(--container-padding);
  }
}

.noTitle {
  padding-top: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media (--tablet-landscape-up) {
    margin-bottom: 16px;
  }
}

.latest {
  display: none;

  @media (--tablet-landscape-up) {
    overflow: hidden;
    display: block;
    height: 20px;
    @mixin font-family-title;
    @mixin font-boldest;
    font-size: 0;
    letter-spacing: 1px;
    text-align: right;
    text-transform: uppercase;
    @mixin triangle-icon-right;

    &:hover {
      font-size: 14px;

      .latestAnimation {
        display: none;
      }
    }
  }
}

.latestAnimation {
  height: 100%;

  span {
    display: block;
    height: 45px;
    margin-bottom: 45px;
    @mixin font-family-title;
    @mixin font-boldest;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: right;
    text-transform: uppercase;
    color: var(--black);
  }
}

.posts {
  overflow-y: scroll;
  white-space: nowrap;
  margin-left: calc(var(--container-padding) * -1);
  padding-left: var(--container-padding);
  padding-bottom: 20px;

  @media (--tablet-landscape-up) {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 28px;
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  @mixin scrollbar-height;
}

.postWrap {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 80%;
  margin-right: 11px;
  height: 100%;

  @media (--tablet-landscape-up) {
    width: 100%;
    margin-right: 0;
  }
}

.post {
  display: flex;
  flex-direction: column;
}

.postImgWrap {
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  background-color: #f6f6f6;

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(4 / 3 * 100%);
  }
}

body .postImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  img {
    @mixin fill-image;
    object-position: top center;
  }
}

.postTitle,
.postSubtitle {
  margin-bottom: 0;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.postSubtitle {
  @mixin font-thin;
  white-space: normal;
}

.overlayBtn {
  @mixin btn-reset;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-default);
}

.overlay {
  background-color: var(--white);
  z-index: var(--z-vegeta);
}

.overlayVisible {
  .overlayCloseBtn {
    /* @mixin animation-fadeInItems 0.3s; */
  }
}

.overlayCloseBtn {
  /* opacity: 0; */
  top: 10px;
  left: initial;
  right: 20px;
  width: 44px;
  height: 44px;
  background-image: var(--close-icon-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.overlayContent {
  padding-top: 30px;
  background-color: var(--white);
}

.overlayContent,
.overlayArticles {
  @mixin max-width 1300px;
}
