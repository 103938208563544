@import '../../../styles/variables.css';

.container {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
    padding: 0 var(--container-padding) 34px;
  }
}

.posts {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 0px;
}

.post {
  display: flex;
  flex-direction: column;
}

body .media {
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 18px;

  picture,
  video {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.title,
.subtitle {
  height: 20px;
  margin-bottom: 0;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.subtitle {
  @mixin font-thin;
}
