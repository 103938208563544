@import '../../../styles/variables.css';

.container {
    display: none;

    @media (--tablet-landscape-up) {
        display: block;
        padding: 0 var(--container-padding) 34px;
    }

    p {
        margin-bottom: 16px;
    }

    p:last-child {
        margin-bottom: 0;
    }

    a {
        font-weight: bold;
    }
}
