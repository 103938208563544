@import '../../styles/variables.css';

.container {
    display: flex;
    flex-direction: column-reverse;
    padding-top: var(--header-height);

    @media (--tablet-landscape-up) {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        grid-gap: 46px;
        @mixin max-width var(--container-max-width);
        padding: var(--header-height) var(--container-padding) 110px;
    }
}

.wrapper {
    overflow-x: hidden;
    @mixin max-width var(--container-max-width);
}

.sidebar {
    @media (--tablet-landscape-up) {
        flex-shrink: 0;
        padding-top: 25px;
    }
}

.sidebarContent {
    padding: var(--container-padding);

    @media (--tablet-landscape-up) {
        position: sticky;
        top: var(--header-height);
        padding: 0;
    }
}

body .title {
    margin-bottom: 0;
    @mixin font-boldest;
    @mixin font-family-title;
    font-size: 36px;
    line-height: 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.model {
    display: block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.date {
    display: block;
    margin-top: 20px;
    @mixin font-family-title;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media (--tablet-landscape-up) {
        margin-top: 26px;
    }
}

.tags {
    margin-top: 20px;

    @media (--tablet-landscape-up) {
        margin-top: 25px;
        padding: 0;
    }
}

.tag {
    margin-right: 50px;
    margin-bottom: 25px;

    @media (--tablet-landscape-up) {
        margin-bottom: 16px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.label,
.name {
    margin-bottom: 0;
    @mixin font-family-title;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.label {
    @mixin font-boldest;

    @media (--tablet-landscape-up) {
        margin-bottom: 8px;
    }
}

.names {
    display: flex;
    flex-wrap: wrap;

    .name {
        margin-right: 15px;
        margin-bottom: 5px;
    }
}

.name {
    @mixin font-thin;
}
