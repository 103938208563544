@import '../../../styles/variables.css';

.container {
  position: relative;
  padding: 26px 0 26px var(--container-padding);

  @media (--tablet-landscape-up) {
    padding: 41px var(--container-padding) 37px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    @mixin breakout-container;
    position: absolute;
    height: 1px;
    background-color: #bcbcbc;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

body .title {
  margin-bottom: 20px;

  @media (--tablet-landscape-up) {
    margin-bottom: 40px;
  }
}

.posts {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  margin-left: calc(var(--container-padding) * -1);
  padding-left: var(--container-padding);
  padding-bottom: 20px;

  @media (--tablet-landscape-up) {
    overflow: hidden;
    display: flex;
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  @mixin scrollbar-height;
}

.postsMobile {
  @media (--tablet-landscape-up) {
    display: none;
  }
}

.btn {
  @mixin btn-reset;
  display: inline-block;
  width: 40%;
  margin-right: 6px;

  @media (--tablet-landscape-up) {
    width: 11.111%;
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.imgWrapper {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
}

.img {
  @mixin fill-image;
  position: absolute;
  top: 0;
  left: 0;
}

.link {
  display: table;
  margin-top: 24px;
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (--tablet-landscape-up) {
    margin-top: 36px;
    margin-bottom: 40px;
    font-size: 16px;
  }

  @mixin triangle-icon-left;
}

.slider {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  z-index: var(--z-lightbox);

  .posts {
    margin-left: 0;

    @media (--tablet-landscape-up) {
      display: block;
      overflow-x: scroll;
      padding-left: var(--container-padding);
      padding-bottom: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--black);
    }
  }

  .btn {
    width: 60%;
    margin-right: var(--container-padding);
    cursor: default;

    @media (--tablet-landscape-up) {
      width: 20%;
      margin-right: 22px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.overlayCloseBtn {
  @mixin btn-reset;
  top: 10px;
  left: initial;
  right: 10px;
  width: 44px;
  height: 44px;
  background-image: var(--close-icon-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}

.overlayLink {
  display: inline-flex;
  align-items: center;
  margin-top: 25px;
  padding-left: var(--container-padding);
  @mixin font-family-title;
  @mixin font-boldest;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  color: var(--black);

  svg {
    display: block;
    margin-left: 10px;

    path {
      stroke: var(--black);
    }
  }
}
